<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <admin-statistics :data="statistics" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="8">
        <admin-debt-report
          :data="data.revenue"
          :debt="debt"
          @refresh="getDashboardSummary($event)"
        />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <admin-refinements
          :data="data.transactionData"
          :refinements="refinements"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="8">
        <admin-loan-table :table-data="latest_loans" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import AdminStatistics from './AdminStatistics.vue'
import AdminLoanTable from './AdminLoanTable.vue'
import AdminDebtReport from './AdminDebtReport.vue'
import AdminRefinements from './AdminRefinements.vue'

export default {
  components: {
    BRow,
    BCol,

    AdminStatistics,
    AdminLoanTable,
    AdminDebtReport,
    AdminRefinements,
  },
  data() {
    return {
      data: {},
      statistics: [],
      latest_loans: [],
      refinements: [],
      debt: {},
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.name
      })

    this.getDashboardSummary()
  },
  methods: {
    getDashboardSummary(year) {
      let url = '/v1/dashboard/admin'

      if (year != null) {
        url += `?year=${year}`
      }

      this.$http.get(url)
        .then(response => {
          this.statistics = response.data.data.statistics
          this.latest_loans = response.data.data.latest_loans
          this.debt = response.data.data.debt
          this.refinements = response.data.data.refinements
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
