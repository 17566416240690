<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Log Kerusakan</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
        v-for="refinement in refinements"
        :key="refinement.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="resolverVariantStatus(refinement.status)"
            >
              <feather-icon
                size="18"
                :icon="resolverAvatarStatus(refinement.status)"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ refinement.inventory.name }}
            </h6>
            <small>{{ refinement.refinement_date }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ resolverStatus(refinement.condition) }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    refinements: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    resolverStatus(condition) {
      if (condition === 1) {
        return 'Baik'
      }

      if (condition === 2) {
        return 'Rusak ringan'
      }

      if (condition === 3) {
        return 'Rusak sedang'
      }

      if (condition === 4) {
        return 'Rusak berat'
      }

      if (condition === 5) {
        return 'Tidak bisa diperbaiki'
      }

      return 'Hilang'
    },
    resolverVariantStatus(status) {
      if (status === 'unrefined') {
        return 'warning'
      }

      if (status === 'refined') {
        return 'success'
      }

      return 'danger'
    },
    resolverAvatarStatus(status) {
      if (status === 'unrefined') {
        return 'AlertCircleIcon'
      }

      if (status === 'refined') {
        return 'CheckCircleIcon'
      }

      return 'XCircleIcon'
    },
  },
}
</script>
